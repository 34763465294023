import axios from "axios";
import { useApplicationStore } from "@/store/application";

const baseDomain = "https://mail9.dev.mybill.ru";

export const customHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const baseUrl = `${baseDomain}`;

const httpClient = axios.create({
  baseURL: baseUrl,
  headers: customHeaders,
  withCredentials: true,
});

httpClient.interceptors.response.use(
  (config) => config,
  (error) => {
    const applicationStore = useApplicationStore();
    if (error.response?.status === 401) {
      applicationStore.toggleToast({
        type: "warning",
        message:
          "Вас долго не было. Поэтому мы завершили ваш сеанс в личном кабинете.",
      });
      applicationStore.setLogout();
      window.location.href = "/";
      return;
    }
    throw error;
  }
);

export default httpClient;
