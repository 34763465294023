import { logoutRequest } from "./../repositories/auth/index";
import { useApplicationStore } from "@/store/application";
import { computed } from "vue";
import { useRouter } from "vue-router";

export const useAuth = () => {
  const applicationStore = useApplicationStore();
  const router = useRouter();

  const isAuth = () => {
    return applicationStore.isAuth();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const checkAuth = (targetFb: (...args: any[]) => void) => {
    return () => {
      if (isAuth()) {
        targetFb();
      } else {
        showLogin();
      }
    };
  };

  const showLogin = () => {
    applicationStore.setCurrentModal("login");
  };

  const showRegistration = () => {
    applicationStore.setCurrentModal("registration");
  };

  const showRecovery = () => {
    applicationStore.setCurrentModal("recovery");
  };

  const showRemind = () => {
    applicationStore.setCurrentModal("remind");
  };

  const closeModal = () => {
    applicationStore.setCurrentModal(null);
  };

  const currentModal = computed(() => {
    return applicationStore.currentModal;
  });

  const isLogin = computed(() => {
    return applicationStore.currentModal === "login";
  });

  const isRegistration = computed(() => {
    return applicationStore.currentModal === "registration";
  });

  const isRecovery = computed(() => {
    return applicationStore.currentModal === "recovery";
  });

  const isRemind = computed(() => {
    return applicationStore.currentModal === "remind";
  });

  const isLoading = computed(() => {
    return applicationStore.isLoading;
  });

  const isAuthUser = computed(() => {
    return applicationStore.auth;
  });

  const currentAuthButton = computed(() => {
    return applicationStore.auth ? "Выход" : "Вход";
  });

  const logout = async () => {
    try {
      applicationStore.showLoader();
      await logoutRequest();
      applicationStore.setLogout();
    } finally {
      applicationStore.hideLoader();
    }
  };

  const onLogout = async () => {
    await logout();
    await router.push({ name: "home" });
  };

  const userInfo = computed(() => {
    return applicationStore.user;
  });

  const showToast = computed(() => applicationStore.showToast);

  const toggleToast = (params: {
    type: "success" | "warning" | "error" | null;
    message: string | null;
  }) => {
    applicationStore.toggleToast(params);
  };

  return {
    isAuth,
    isLogin,
    isRegistration,
    isRecovery,
    isRemind,
    isLoading,
    showLogin,
    showRegistration,
    showRecovery,
    showRemind,
    closeModal,
    currentModal,
    checkAuth,
    currentAuthButton,
    logout,
    applicationStore,
    isAuthUser,
    userInfo,
    onLogout,
    showToast,
    toggleToast,
  };
};
