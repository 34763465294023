import { OrderInformation, useApplicationStore } from "@/store/application";
import { computed } from "vue";

export const useStore = () => {
  const applicationStore = useApplicationStore();

  const userInfo = computed(() => {
    return applicationStore.user;
  });

  const saveOrderInformation = async (order: OrderInformation) => {
    applicationStore.saveOrderInformation(order);
  };

  const showToast = computed(() => applicationStore.showToast);

  const toggleToast = (params: {
    type: "warning" | "error" | null;
    message: string | null;
  }) => {
    applicationStore.toggleToast(params);
  };

  const hideToast = () => {
    applicationStore.hideToasts();
  };

  const countBasket = computed(() => applicationStore.user?.countBasket ?? 0);

  const increaseCountBasket = (qty: number) => {
    applicationStore.setCountBasket((countBasket.value ?? 0) + qty);
  };

  const decreaseCountBasket = (qty: number) => {
    applicationStore.setCountBasket((countBasket.value ?? 0) - qty);
  };

  const clearBasket = () => {
    applicationStore.setCountBasket(0);
  };

  const isAuthUser = computed(() => {
    return applicationStore.auth;
  });

  const currentUser = computed(() => {
    return applicationStore.getUser();
  });

  const getOrderInformation = async () => {
    return await applicationStore.getOrderInformation();
  };

  return {
    showToast,
    toggleToast,
    userInfo,
    countBasket,
    increaseCountBasket,
    decreaseCountBasket,
    isAuthUser,
    saveOrderInformation,
    getOrderInformation,
    hideToast,
    currentUser,
    clearBasket,
  };
};
