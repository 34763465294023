import "./assets/scss/style.scss";
import "./assets/style/main.css";
import "./assets/style/tailwind.css";
import "vue-toast-notification/dist/theme-sugar.css";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
// import store from "./store";
import VueLazyload from "vue-lazyload";
import Sticky from "vue-sticky-directive";
import VueEasyLightbox from "vue-easy-lightbox";
import VCalendar from "v-calendar";
import PrimeVue from "primevue/config";
import VueSelect from "vue-select";
import "v-calendar/style.css";
import { createPinia } from "pinia";
import buildDateData from "@/build-date.json";
import "ant-design-vue/dist/reset.css";
import Antd from "ant-design-vue";
import Tooltip from "primevue/tooltip";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import { createYmaps } from "vue-yandex-maps";
import ToastPlugin from "vue-toast-notification";

console.log(`Build Date: ${buildDateData.buildDate}`);

const store = createPinia();
const app = createApp(App);
app.use(Sticky);
app.use(router);
app.use(ToastPlugin);
app.use(Antd);
app.use(store);
app.use(VueEasyLightbox);
app.component("v-select", VueSelect);
app.use(VueLazyload);
app.use(PrimeVue);

// TODO: please change API key on your own
app.use(
  createYmaps({
    apikey: "658ec761-39cc-4de7-b846-ac95a5d469e1",
  })
);
app.directive("tooltip", Tooltip);
app.mount("#app");
app.use(VCalendar, {
  locales: {
    "ru-RU": {
      masks: {
        weekdays: "WW",
      },
    },
  },
});
